// Add here all polyfills needed

/**
 * !!! README first !!!
 *
 * Instead of installing pollyfills penalizing modern browsers,
 * we are using a service from https://polyfill.io/
 *
 * They have most of the polyfills we need (check their feature list
 * here: https://polyfill.io/v2/docs/features/)
 *
 * There are some good examples here: https://polyfill.io/v2/docs/examples
 */

// import 'intersection-observer';

/** IE polyfills */

/* installing package: yarn add core-js */
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';
// import 'core-js/es6/promise';

// import 'core-js/es6/reflect';

/** end IE polyfills */
